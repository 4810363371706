/* Fonts */

// Lora
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
// Nunito Sans
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

/* General elements */
body {
	overflow-x: hidden;
}

a {
	font-family: "Nunito Sans", sans-serif;
	font-weight: 800;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

/* Header */
.header {
	background-color: $white;
	@include media-breakpoint-down(md) {
		border-bottom: 1px solid $biscay;
	}
	> .container {
		padding: 1.25rem 0 0.5rem 0;
		@include media-breakpoint-down(md) {
			padding: 0.5rem 0 0 0;
		}
	}
	.header-logo-container {
		@include media-breakpoint-down(md) {
			justify-content: end;
			padding-right: 0.5rem;
		}
	}
	.header-logo {
		@include media-breakpoint-down(md) {
			display: none;
		}
		h1 {
			max-width: 400px;
			margin-bottom: 0;
		}
		p {
			margin-left: 150px;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				margin-left: 115px;
				a {
					font-size: 0.9rem;
					span {
						font-size: 1rem;
					}
				}
			}
			@include media-breakpoint-down(sm) {
				display: none;
			}
			a {
				font-family: $font-family-georgia;
				font-style: italic;
				font-weight: 500;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
				span {
					font-size: 1.1rem;
					font-style: normal;
					color: $shiraz;
				}
			}
		}
	}
	.header-mobile-logo {
		@include media-breakpoint-down(md) {
			display: block !important;
			margin-top: -2rem;
		}
	}
	.login {
		ul {
			list-style: none;
			text-transform: capitalize;
			font-size: 14px;
			margin-top: 0.5rem;
			li {
				margin: 0 8px;
				float: right;
				padding-left: 0px;
				padding-right: 12px;
				margin-right: 4px;
				border-right: 2px solid $bright-sun;
				&:first-child {
					border-right: none;
				}
			}
		}
	}
	.header-right {
		.nav {
			font-size: 0.9rem;
			.nav-item {
				padding: 0;
				&:hover {
					.nav-link {
						color: $biscay;
						text-decoration: underline;
					}
				}
				&:last-child {
					.nav-link {
						&::after {
							content: "";
							padding-left: 0;
						}
					}
				}
				.nav-link {
					color: $biscay;
					font-weight: bold;
					padding: 0.5rem 0 0.5rem 0.5rem;
					&::after {
						content: "|";
						color: $bright-sun;
						padding-left: 0.5rem;
					}
				}
			}
		}
		.search-form {
			margin-left: 0.75rem;
			.form-control {
				width: 175px;
				font-size: 0.9rem;
			}
			.btn {
				color: $biscay;
				border: 0;
				font-size: 0.9rem;
				padding-right: 0;
				&:active,
				&:hover,
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
			::placeholder {
				color: $congress-blue;
				font-weight: bold;
			}
		}
		.navbar-toggler {
			color: $white;
			background-color: $shiraz;
			&:focus {
				outline: none;
				box-shadow: none;
			}
			&.collapsed {
				.fa-bars {
					display: inline;
				}
				.fa-times {
					display: none;
				}
			}
			&:not(.collapsed) {
				.fa-bars {
					display: none;
				}
				.fa-times {
					display: inline;
				}
			}
		}
		.social-links {
			.list-inline-item:not(:last-child) {
				margin-right: 0.25rem;
			}
		}

		.social-links [class*="fa"] {
			background-color: $burgundy;
			border-radius: 30px;
			color: $white;
			height: 35px;
			width: 35px;
			padding: 8px;
		}
		.hash-tag {
			text-decoration: none;
			font-family: "Lora", serif;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.navbar {
		background-color: $biscay;
		padding: 0;
		font-size: 1rem;
		height: 50px;
		@include media-breakpoint-down(md) {
			height: 0px;
		}
		.navbar-collapse {
			@include media-breakpoint-down(md) {
				background-color: $burgundy;
			}
			.navbar-nav {
				.nav-item {
					&:hover {
						.nav-link {
							text-decoration: underline;
						}
					}
					&:last-child {
						.nav-link {
							padding-right: 0;
						}
					}
					.nav-link {
						color: $white;
						font-weight: 400;
					}
				}
				.dropdown-menu {
					min-width: 250px;
					background-color: $biscay;
					margin-top: -0.25rem;
					@include media-breakpoint-down(md) {
						background-color: $burgundy;
					}
					.dropdown-item {
						font-size: 0.9rem;
						color: $white;
						&:hover,
						&:focus {
							background-color: inherit;
							text-decoration: underline;
						}
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			//margin-top:2rem;
			.container {
				padding: 0;
			}
			form {
				padding: 0.5rem 0.5rem 0.5rem 1rem;
				border-bottom: solid 1px $white;
				border-top: solid 1px $white;
				button {
					color: $white;
				}
			}
			.navbar-nav {
				.nav-item {
					border-bottom: solid 1px $white;
					padding-left: 1rem;
					&:last-child {
						border-bottom: none;
					}
				}
				.dropdown-menu {
					background-color: inherit;
					border: 0;
					margin-top: 0;
					padding-top: 0;
					.dropdown-item {
						color: $white;
						font-size: 0.9rem;
					}
				}
			}
		}
		.static-menu {
			@include media-breakpoint-down(sm) {
				margin-top: -2.2rem;
			}
			.btn-menu {
				padding: 0.75rem 1rem;
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;
				text-align: center;
				line-height: 1.25;
				font-size: 1.25rem;
				text-decoration: none;
				margin: 0rem 0.5rem 1.5rem 0rem;
				position: relative;
				&:hover {
					background-color: $shiraz;
					border-color: $shiraz;
				}
				&:active {
					background-color: $shiraz;
					border-color: $shiraz;
				}
				.menu-small {
					font-size: 1rem !important;
					font-weight: 400;
				}
				@include media-breakpoint-down(lg) {
					font-size: 1rem;
					padding: 0.5rem;
				}
				@include media-breakpoint-down(sm) {
					font-size: 1rem;
					margin: 0rem 0.5rem 0rem 0.75rem;
					padding: 0.75rem 1rem;
				}
				&.btn-burgundy {
					color: $white;
					background-color: $burgundy;
					border-color: $burgundy;
					&:hover {
						background-color: $shiraz;
						border-color: $shiraz;
					}
				}
			}
		}
		.static-mobile-menu {
			background-color: $burgundy;
			width: 100%;
		}
	}
	#header-banner {
		.banner-image {
			width: 100% !important;
			object-fit: cover;
			max-height: 325px !important;
			@include media-breakpoint-down(sm) {
				content: url(../images/military-army-mobile.svg);
				width: 100% !important;
			}
		}
		.close-btn {
			/*border: 2px solid white;
			position: absolute;
			top: 30px;
			border-radius: 27px;
			height: 27px;
			width: 27px;
			padding: 0px 1px 0px 6px;
			right: 2%;
			color: white;
			font-weight: bold;*/
			position: absolute;
			top: 50px;
			right: 2%;
			color: $white;
			font-size: 2rem;
			cursor: pointer;
			z-index: 100;
			@include media-breakpoint-down(md) {
				top: 30px !important;
			}
			a {
				color: $white;
				text-decoration: none;
			}
		}
		.show-btn {
			float: right;
			position: relative;
			right: 2%;
			font-size: 2rem;
			color: $congress-blue;
			cursor: pointer;
			display: none;
		}
		.banner-arrow {
			position: absolute;
			top: 100px;
			@include media-breakpoint-down(xl) {
				top: 50px !important;
			}
			@include media-breakpoint-down(lg) {
				top: 30px !important;
			}
		}
		.need-help {
			position: absolute;
			top: 130px;
			color: $white;
			font-size: 1.5rem;
			@include media-breakpoint-down(xl) {
				top: 80px !important;
			}
			@include media-breakpoint-down(lg) {
				top: 60px !important;
			}
		}
		.banner-container {
			position: absolute;
			top: 145px;
			@include media-breakpoint-down(xl) {
				top: 100px !important;
			}
			@include media-breakpoint-down(lg) {
				top: 80px !important;
			}
			@include media-breakpoint-down(sm) {
				top: 90px !important;
			}
			.banner-btn {
				width: 175px;
				text-align: center;
				border-radius: 5px;
				color: $black;
				text-decoration: none !important;
				@include media-breakpoint-down(sm) {
					margin: 0.5rem !important;
					line-height: 1;
				}
			}
		}
	}
	.static-btn {
		font-size: 1.1rem;
		font-weight: 700;
		border-radius: 0;
		justify-content: center;
	}
}

/* Page tools */
#m-header {
	height: auto !important;
	z-index: auto !important;
	margin-top: 0 !important;
	padding-top: 0 !important;
	position: static !important;
	margin-bottom: 0.75rem;
	overflow: hidden;

	.tool-title,
	.tool-title h2 {
		float: left;
		display: inline;

		@include media-breakpoint-down(md) {
			font-size: calc(1.325rem + 0.9vw);
		}
	}

	.page-tools {
		font-size: 0.85rem;
		list-style-type: none;
		position: inherit;
		float: right;
		margin: 0;

		@include media-breakpoint-down(md) {
			display: none !important;
		}

		&.v2 {
			li {
				a {
					color: $congress-blue !important;
					width: auto;
					font-size: 0.9rem;
					padding: 1.5rem 0.5rem 0 0.5rem;

					&::before {
						color: $congress-blue !important;
						font-size: 18px;
					}
				}
			}
		}

		&:not(.v2) {
			li {
				float: left;
				width: auto;
				padding-left: 0.25rem;
				padding-right: 0.25rem;

				&::before {
					content: "|";
					color: $congress-blue;
					font-weight: bold;
					padding-right: 0.25rem;
					padding-left: 0.25rem;
				}

				&:first-child {
					padding-left: 0;

					&::before {
						content: "";
						padding-right: 0;
						padding-left: 0;
					}
				}

				&.first {
					display: none;

					+ li {
						&::before {
							content: "";
							padding-right: 0;
							padding-left: 0;
						}
					}
				}

				a {
					padding-left: 1.75rem;

					&.email {
						background: url(../images/icons/email.png) 5px center no-repeat;
					}

					&.print {
						background: url(../images/icons/printer.png) 5px center no-repeat;
					}

					&.add-item {
						background: url(../images/icons/document.png) 5px center no-repeat;
					}

					&.settings {
						background: url(../images/icons/cog.png) 5px center no-repeat;
					}

					&.rss {
						background: url(../images/icons/rss.png) 5px center no-repeat;
					}

					&.admin {
						background: url(../images/icons/doc.png) 5px center no-repeat;
					}

					&.widget {
						background: url(../images/icons/widget.png) 5px center no-repeat;
					}

					&.home {
						background: url(../images/icons/application_home.png) 5px center no-repeat;
					}

					&.hide-sidebar {
						background: url(../images/icons/application_sidebar.png) 5px center no-repeat;
					}

					&.external-content {
						background: url(../images/icons/application_external.png) 5px center no-repeat;
					}

					&.reports {
						background: url(../images/icons/application_reports.png) 5px center no-repeat;
					}

					&.labels {
						background: url(../images/icons/table_edit.png) 5px center no-repeat;
					}
				}
			}
		}

		&.my-profile {
			li {
				padding: 0;

				a {
					padding-left: 0;
				}
			}
		}
	}

	+ .back,
	.back {
		background-color: $congress-blue !important;
		color: $white !important;
		margin: 10px 0 0 0 !important;
		height: auto !important;
		width: 100%;
		float: left;
		clear: both;
		font-size: 0.8rem !important;
		padding: 0.5rem !important;
		a {
			color: $white;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/* Main */
.main {
	background-color: $white;
	padding: 1.5rem 0 1.5rem;
	&.home {
		background-color: $congress-blue;
		color: $white;
	}
}

.home-section {
	#banner {
		.home-page-banner {
			height: 325px;
			border-radius: 0.5rem;
			@include media-breakpoint-up(xxl) {
				height: 350px;
			}
			.banner-content {
				font-size: 1.4rem;
				color: $congress-blue;
				width: 55% !important;
				@include media-breakpoint-down(xl) {
					font-size: 1.3rem;
				}
				@include media-breakpoint-down(md) {
					font-size: 1.1rem;
				}

				@include media-breakpoint-down(sm) {
					font-size: 1rem;
					padding: 0.5rem !important;
					width: 155px;
				}
			}
			.hero-image {
				width: 45% !important;
				img {
					top: -90px;
					position: relative;
					@include media-breakpoint-up(xxl) {
						top: -135px;
					}
					@include media-breakpoint-down(xl) {
						top: -25px;
					}
					@include media-breakpoint-down(lg) {
						top: -70px;
					}
					@include media-breakpoint-down(md) {
						top: 0px;
					}
				}
			}
		}
	}
	#latest-announcements {
		h3 {
			background-color: $bright-sun;
			margin-bottom: 0rem;
			padding: 0.5rem 1.5rem;
			font-size: 1.3rem;
			font-weight: bold;
			color: $congress-blue;
		}
		.carousel-inner {
			border: 2px solid $bright-sun;
			border-radius: 0.5rem;
			.carousel-item {
				height: 280px;
				@include media-breakpoint-up(xxl) {
					height: 305px;
				}
				@include media-breakpoint-down(lg) {
					height: 400px;
				}
				@include media-breakpoint-down(md) {
					height: 320px;
				}
				@include media-breakpoint-down(sm) {
					height: 280px;
				}
				.carousel-caption {
					bottom: 0rem !important;
					padding: 0rem !important;
				}
			}
		}
		.carousel-control-prev,
		.carousel-control-next {
			top: 210px;
			@include media-breakpoint-down(lg) {
				top: 380px;
			}
			@include media-breakpoint-down(md) {
				top: 210px;
			}
		}
	}
	#our-impact {
		h3 {
			background-color: $mercury;
			margin-bottom: 0rem;
			padding: 0.5rem 1.5rem;
			font-size: 1.3rem;
			font-weight: bold;
			color: $congress-blue;
		}
		.carousel-inner {
			background-color: $white;
			border-radius: 0.5rem;
			.carousel-item {
				height: 320px;
				@include media-breakpoint-down(lg) {
					height: 200px;
				}
				@include media-breakpoint-down(sm) {
					height: 225px;
				}
				.container {
					display: flex !important;
					height: 100% !important;
					.carousel-caption {
						color: $congress-blue;
						margin: auto 0 !important;
						padding: 10px 20px;
						position: relative;
						right: 0;
						bottom: 0rem;
						left: 0;
					}
				}
			}
		}
		.carousel-control-prev-icon {
			background-image: url(../images/previous-icon.svg) !important;
		}
		.carousel-control-next-icon {
			background-image: url(../images/next-icon.svg) !important;
		}
	}
	.carousel-indicators {
		justify-content: left !important;
		margin-left: 0px !important;
		bottom: -35px !important;
	}
	.carousel-indicators [data-bs-target] {
		width: 10px !important;
		height: 10px !important;
		border-radius: 0.5rem !important;
		border: 0 !important;
	}
	.carousel-viewall {
		float: right;
		a {
			color: $white;
			font-size: 0.9rem;
		}
	}
	#latest-news,
	#upcoming-events {
		em {
			color: $black;
			&::before {
				content: "\A";
				white-space: pre;
			}
		}
	}
	#upcoming-event {
		li {
			position: relative;
			padding: 0.5rem 0rem 0.75rem 4.5rem;
			min-height: 4rem;
		}
		em {
			position: absolute;
			top: 10px;
			left: 0;
			width: 65px;
			background-color: $mercury;
			text-align: center;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: bold;
			color: $congress-blue;
			padding: 3px;
			height: 45px;
		}
	}
	.whatsnew {
		background-color: $white;
		border-radius: 0.5rem;
		height: 100%;
		h3 {
			background-color: $mercury;
			border-radius: 0.5rem 0.5rem 0 0;
			padding: 0.5rem 1.5rem;
			font-size: 1.3rem;
			font-weight: bold;
			color: $congress-blue;
		}
		.feed {
			padding: 0.5rem 1.5rem 1.5rem 1.5rem;
			li {
				padding: 0.75rem 0rem 0.75rem 0rem;
				border-top: 2px solid $mercury;
				&:first-child {
					border-top: none !important;
				}
				&.more {
					text-align: right;
					border-top: none !important;
					padding: 0.75rem 0rem 0.75rem 0rem;
					&::after {
						content: ">";
						background-color: $shiraz;
						border-radius: 55%;
						border: 1px solid $shiraz;
						padding: 3px 10px;
						font-size: 20px;
						margin-left: 5px;
						font-weight: bold;
					}
				}
			}
		}
	}
}

.programs-clinics,
.volunteer-info,
.legal-help {
	main {
		padding-top: 0rem !important;
		.content-section {
			> .container {
				max-width: 1400px;
				> div {
					padding: 0rem 7rem;
					@include media-breakpoint-down(xl) {
						padding: 0rem 4rem;
					}
					@include media-breakpoint-down(lg) {
						padding: 0rem 2rem;
					}
					@include media-breakpoint-down(sm) {
						padding: 0rem 0.5rem;
					}
				}
				> div:nth-child(1) {
					background-color: $jagged-ice;
				}
				> div:nth-child(2) {
					background-color: $jagged-ice;
				}
			}
		}
	}
}

.volunteer-info {
	#banner {
		.banner-content {
			p {
				font-size: 1.3rem;
				font-weight: 700;
				margin-bottom: 5rem;
			}
			a {
				font-size: 1rem;
			}
		}
	}
	#album_title {
		font-weight: bold;
		font-size: 1.25rem;
	}
	.bootstrap-album {
		.row:nth-child(2) {
			justify-content: center;
		}
		.card-column {
			@include media-breakpoint-down(xl) {
				flex: 0 0 auto !important;
				width: 50% !important;
			}
		}
		.card {
			align-items: center;
			background-color: $burgundy;
			margin: 0.5rem 0.5rem 0.5rem 0rem;
			border-radius: 0.5rem;
			height: 185px !important;
			img {
				padding: 1rem;
				@include media-breakpoint-down(sm) {
					padding: 1rem 1rem 0rem;
				}
			}
			.card-text {
				font-size: 1.25rem;
				color: $white;
				text-align: center;
				@include media-breakpoint-down(sm) {
					font-size: 1.1em;
				}
			}
		}
	}
	.bootstrap-carousel {
		h3 {
			background-color: $mercury;
			margin-bottom: 0rem;
			padding: 0.5rem 1.5rem;
			font-size: 1.3rem;
			font-weight: bold;
			color: $congress-blue;
		}
		.carousel-inner {
			border: 2px solid $mercury;
			border-radius: 0.5rem;
			.carousel-item {
				height: 260px;
				/*.carousel-image{
				//max-width:230px !important;
				//max-height:260px !important;
				//margin:0 auto;
				//padding:1.5rem 0rem;
				//width: auto !important;
			}*/
				.carousel-caption {
					bottom: 0rem !important;
					padding-top: 0rem;
					padding-bottom: 0.75rem !important;
					h2 {
						font-size: 1.28rem;
						color: $shiraz;
						font-weight: bold;
					}
					p {
						font-size: 1rem;
						color: $congress-blue;
						font-weight: bold;
						text-decoration: underline;
					}
				}
			}
		}
		.carousel-control-prev,
		.carousel-control-next {
			top: 100px;
		}
		.carousel-control-prev-icon {
			background-image: url(../images/previous-icon.svg) !important;
		}
		.carousel-control-next-icon {
			background-image: url(../images/next-icon.svg) !important;
		}
		.carousel-indicators {
			justify-content: left !important;
			margin-left: 0px !important;
			bottom: -35px !important;
		}
		.carousel-indicators [data-bs-target] {
			width: 10px !important;
			height: 10px !important;
			border-radius: 0.5rem !important;
			border: 0 !important;
			background-color: $congress-blue !important;
		}
		.carousel-viewall {
			float: right;
			a {
				color: $congress-blue;
				font-size: 0.9rem;
			}
		}
	}
	#volunteer_links {
		div {
			border-radius: 0.5rem;
			margin: 3rem 0rem;
			ul {
				list-style: none;
				line-height: 1.75rem;
				margin-bottom: 0rem !important;
			}
		}
	}
}

.bootstrap-accordion {
	.accordion-item {
		border-radius: 0;
		border: none;
		.accordion-button {
			background-color: $gray;
			color: $congress-blue;
			border-radius: 0 !important;
			box-shadow: none;
			border-bottom: 1px solid $white;
			&::after {
				background-image: escape-svg(
					url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$congress-blue}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
				);
			}
			&:hover {
				&::after {
					background-image: escape-svg(
						url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$congress-blue}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
					);
				}
			}
		}
	}
}

body.vnap,
body.cavc,
body.discharge-upgrade,
body.legal-clinics,
body.wvlap,
body.faq {
	.bootstrap-accordion {
		.accordion-item {
			.accordion-button {
				background-color: $white;
				border: 0px;
				border-bottom: 2px solid $mercury;
			}
			&:first-child {
				.accordion-button {
					border-top: 2px solid $mercury;
				}
			}
		}
	}
}

#vnap_tools {
	.card {
		border-radius: 0.5rem;
		word-wrap: normal !important;
		@include media-breakpoint-down(sm) {
			min-height: 180px;
		}
		.card-body {
			a {
				font-size: 1.25rem;
				text-decoration: none;
			}
			p {
				line-height: 1.3;
			}
		}
	}
}

#content-section {
	#header_content {
		a {
			font-size: 1.1rem;
		}
	}
	#featured_video {
		h3 {
			background-color: $mercury;
			margin-bottom: 0rem;
			padding: 0.5rem 1.5rem;
			font-size: 1.3rem;
			font-weight: bold;
			color: $congress-blue;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
		}
	}
	#rightbar {
		@include media-breakpoint-down(md) {
			margin-top: 2rem;
		}
	}
}

.discharge-upgrade,
.appeals-cavc,
.volunteer-info,
.legal-help,
.programs-clinics {
	#banner {
		.banner-content {
			p {
				font-size: 1.3rem;
				font-weight: 700;
				margin-bottom: 5rem;
			}
			a {
				font-size: 1rem;
			}
		}
	}
	#album_title {
		font-weight: bold;
		font-size: 1.5rem;
	}
	.bootstrap-album {
		margin: 0rem 7rem;
		@include media-breakpoint-down(xl) {
			margin: 0rem 4rem;
		}
		@include media-breakpoint-down(lg) {
			margin: 0rem 2rem;
		}
		@include media-breakpoint-down(sm) {
			margin: 0rem 0.5rem;
		}
		.row:nth-child(2) {
			justify-content: center;
		}
		.card-column {
			@include media-breakpoint-down(md) {
				flex: 0 0 auto !important;
				width: 50% !important;
			}
		}
		.card {
			align-items: center;
			background-color: $congress-blue;
			margin: 0.5rem 0.5rem 0.5rem 0rem;
			border-radius: 0.5rem;
			min-height: 185px !important;
			img {
				padding: 1rem;
				@include media-breakpoint-down(sm) {
					padding: 1rem 1rem 0rem;
				}
			}
			.card-text {
				font-size: 1.25rem;
				color: $white;
				text-align: center;
				@include media-breakpoint-down(sm) {
					font-size: 1.1rem;
				}
			}
		}
	}
}

body.in_the_news {
	.flex-page-home-landing {
		h4.summary {
			font-size: 1.1rem;
		}
	}
}

.content-section {
	/*content-display.calendarApp,content-display.newsApp,content-display.libraryApp,content-display.casesApp,content-display.projectsApp {*/
	#m-header .join-button {
		display: none !important;
	}

	.detail-tools {
		abbr[title] {
			text-decoration: none !important;
		}
		.print,
		.download,
		.email,
		.flag-off,
		.flag-on {
			color: $congress-blue !important;
		}
	}

	content-display.calendarApp,
	content-display.newsApp,
	content-display.libraryApp,
	content-display.casesApp,
	content-display.projectsApp {
		.list {
			.filters {
				background: $congress-blue !important;
				color: $white !important;
				.filter-indicator {
					background: $congress-blue !important;
				}
				.rcMenu {
					.hotspot {
						@include media-breakpoint-down(md) {
							background: $congress-blue !important;
						}
						&:after {
							border-color: $bright-sun transparent transparent transparent !important;
						}
					}
					ul {
						background: $congress-blue !important;
						li {
							&.rcMenuItem {
								color: $white !important;

								&:before {
									border: 1px solid $white;
								}
								&.rcSelected {
									&:after {
										background: $white !important;
										@include media-breakpoint-down(md) {
											top: 17px !important;
											left: 17px !important;
										}
									}
								}
								@include media-breakpoint-down(md) {
									background: $congress-blue !important;
								}
							}
							&:hover {
								background-color: $biscay;
							}
						}
					}
					&.active {
						border-color: $bright-sun transparent transparent transparent !important;
					}
				}
				@include media-breakpoint-down(md) {
					top: 125px !important;
					z-index: 40 !important;
				}
			}
			.showing {
				color: $congress-blue;
			}
			li.rcarticle {
				border-top: 2px solid $mercury;
			}
			.case-status {
				color: $shiraz;
			}
		}

		.small-calendar {
			@include media-breakpoint-down(md) {
				margin-top: 15px !important;
			}
		}
		.small-calendar .asCalendar .fc-body .rcHasEvent.rcHasFocus {
			background: $congress-blue !important;
		}
		.small-calendar .asCalendar .keyHasEvent.keyHasFocus::before {
			background: $congress-blue !important;
		}
		.small-calendar .keyToday::before {
			background: $biscay !important;
		}
		.small-calendar .asCalendar .fc-body .fc-today,
		.small-calendar .asCalendar .fc-body .rcHasFocus.fc-today {
			background: $biscay !important;
			color: $white !important;
		}
		.small-calendar .asCalendar .fc-body .fc-future.rcHasEvent,
		.small-calendar .asCalendar .keyHasEvent::before {
			background: $jagged-ice !important;
			color: $black !important;
		}

		.asCalendar ul {
			.date {
				background: $congress-blue !important;
				color: $white !important;
			}
			li.rcevent {
				border-color: $jagged-ice !important;
			}
		}
	}
	.event h4,
	.event .h4 {
		background: $congress-blue !important;
		color: #fff !important;
	}

	.volunteer-request a {
		background-color: $burgundy !important;
		&:hover {
			color: $white !important;
		}
	}
}

body.library {
	.content-section {
		content-display.libraryApp {
			.library-resource-list {
				.recent {
					margin-left: 0rem !important;
					margin-right: 0rem !important;
				}
				li:before {
					background: $congress-blue !important;
				}
			}
			.expanded-folder::before,
			.collapsed-folder::before {
				color: $congress-blue !important;
			}
			.selected-folder {
				background-color: $jagged-ice !important;
			}
			#root-folder .folder-list folderapp > .folder-list::before {
				background: $congress-blue !important;
			}
		}
	}
}

body.cases {
	.content-section {
		.content-detail {
			.props.lists {
				@include media-breakpoint-down(md) {
					padding: 0px !important;
					div.long-answers {
						background: #8095ab;
						li > div {
							width: 100px;
						}
					}
				}
			}
		}
	}
}

content-display.newsApp {
	.content-alert-message {
		height: 35px !important;
		padding: 5px 10px !important;
		text-transform: uppercase !important;
		font-size: 16px !important;
		&::before {
			content: "!" !important;
			font: 15px "picons-font" !important;
			margin-right: 3px !important;
			position: initial !important;
		}
		&::after {
			content: "" !important;
			position: initial !important;
		}
	}
}

/*general*/
/*Popup event issue in fullcalendar*/
.ui-widget-content #eventDetails .event {
	.h4,
	h4 {
		background: $congress-blue !important;
		color: #fff !important;
	}
	.detail-tools {
		margin-left: 0px;
		abbr[title] {
			text-decoration: none !important;
		}
		.print,
		.download,
		.email,
		.flag-off,
		.flag-on {
			color: $congress-blue !important;
		}
	}
}
/*Popup event close btn*/
.ui-dialog .ui-dialog-titlebar-close {
	padding: 0px 1px !important;
	font-weight: bold !important;
	top: 47% !important;
	height: 21px !important;
}

.ui-dialog .ui-dialog-titlebar-close::before {
	content: "X" !important;
}

/* Flex pages Right Navigation column display in mobile*/
div#AppMain div#AppBody,
div#AppMain div#PostDetail,
div#AppMain div#PostNav {
	@include media-breakpoint-down(md) {
		clear: both;
		width: 100%;
	}
}
/* Flex pages Right Navigation column display in mobile*/

/* G-Translate*/
.language-selector {
	margin-top: -1.5rem !important;
	.language-dropdown {
		position: absolute;
		inset: 0px auto auto 0px;
		margin: 0px;
		transform: translate(-151px, 36px);
		min-width: 200px;
		font-size: 1rem;
	}
	@include media-breakpoint-down(md) {
		position: absolute;
		margin-top: -2.5rem !important;
		margin-left: 0.5rem;
	}
}
/* G-Translate*/

.smaller {
	font-size: 80%;
	font-weight: 500 !important;
}

.smallest {
	font-size: 60%;
}

.mt-n3 {
	margin-top: -1rem !important;
}

.mt-n5 {
	margin-top: -1.5rem !important;
}

.mx-n3 {
	margin-right: -1rem !important;
	margin-left: -1rem !important;
}

/* Footer */
.footer {
	background-color: $mercury;
	padding: 1.5rem 0 1.5rem;
	.left-column {
		.social-links {
			.list-inline-item:not(:last-child) {
				margin-right: 0.25rem;
			}
		}
		.social-links [class*="fa"] {
			background-color: $congress-blue;
			border-radius: 30px;
			color: $white;
			height: 35px;
			width: 35px;
			padding: 8px;
		}
		.disclaimer {
			margin-bottom: 1.5rem;
			font-size: 0.9rem;
		}
		.powered-by {
			color: $shiraz;
			span {
				color: darkblue;
				font-style: italic;
				font-weight: 700;
			}
		}
	}
	.middle-column {
		.donate-button {
			width: 100%;
			margin-bottom: 1.5rem;
		}
		.quick-links {
			margin-bottom: 1.5rem;
			li {
				margin-bottom: 0.5rem;
			}
		}
	}
	.right-column {
		img {
			max-width: 100%;
			max-height: 200px;
		}
	}
}
