/*! vets pro bono */

// Bootstrap functions
@import "bootstrap/scss/functions";

// Bootstrap variable overrides
$font-family-sans-serif: "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
	"Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Other global variables
$font-family-georgia: Georgia, Verdana, Arial, sans-serif;

// Bootstrap variables
@import "bootstrap/scss/variables";

// Custom colors
$congress-blue: #004b87;
$shiraz: #b00c3b;
$mercury: #e4e4e4;
$bright-sun: #ffe733;
$biscay: #183259;
$burgundy: #900029;
$jagged-ice: #d5e8f0;
$gray: #c5c4c4;
$custom-colors: (
	"congress-blue": $congress-blue,
	"shiraz": $shiraz,
	"mercury": $mercury,
	"bright-sun": $bright-sun,
	"biscay": $biscay,
	"burgundy": $burgundy,
	"jagged-ice": $jagged-ice,
	"gray": $gray,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// Bootstrap colors
$body-color: $black;
$link-color: $congress-blue;

// Bootstrap required
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Bootstrap optional
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// Bootstrap helpers
@import "bootstrap/scss/helpers";

// Bootstrap utilities api
@import "bootstrap/scss/utilities/api";

// NProgress
@import "nprogress/nprogress";

// Icon set
//@import "../../pbn-icons/scss/app";

// Site styles
@import "styles";
